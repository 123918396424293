import BaseApi from './BaseApi'

class InterestRatesApi extends BaseApi {
  getAll() {
    return this.get('interestrates')
  }

  create(valid_from, rate) {
    return this.post('interestrates', { valid_from, rate })
  }

  update(id, valid_from, rate) {
    return this.put(`interestrates/${id}`, { valid_from, rate })
  }

  removeEntry(id) {
    return this.delete(`interestrates/${id}`)
  }

  calculateRate(amount, startDate, endDate) {
    return this.get(`interestrates/calculate`, {
      amount,
      startDate,
      endDate,
    })
  }

  getDefaultInterestPeriods() {
    return this.get('default_interest_periods')
  }

  createDefaultInterestPeriod(year, starts_at) {
    return this.post('default_interest_periods', { year, starts_at })
  }

  updateDefaultInterestPeriod(id, year, starts_at) {
    return this.put(`default_interest_periods/${id}`, { year, starts_at })
  }

  removeDefaultInterestPeriod(id) {
    return this.delete(`default_interest_periods/${id}`)
  }
}

export default new InterestRatesApi()
