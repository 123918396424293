import axios from 'axios'
import FileSaver from 'file-saver'

import { keycloak } from '@/api/keycloakAuth'
import { ApiError } from '@/utils/ApiError'

import * as Sentry from '@sentry/vue'

const baseURL =
  window.apiBaseUrl ||
  `${window.location.protocol}//${window.location.host}/api`
const requester = axios.create({ baseURL })

requester.interceptors.request.use(async (config) => {
  const originalRequest = config
  await keycloak.updateToken(30)
  originalRequest.headers.Authorization = `Bearer ${keycloak.token}`
  return originalRequest
})

const debug = process.env.NODE_ENV !== 'production'

export default class BaseApi {
  async request(method, url, data, additionalHeaders) {
    const headers = {}

    if (additionalHeaders) {
      Object.assign(headers, additionalHeaders)
    }

    const requestConfig = { method, url, headers }

    if (data) {
      if (method === 'get') {
        requestConfig.params = data
      } else {
        requestConfig.data = data
      }
    }
    let response
    try {
      response = await requester(requestConfig)
      logResult(response)
      return unwrapData(response)
    } catch (e) {
      handleError(e)
    }
  }

  async downloadAsFile(requestConfig, fileMime, fileName) {
    const config = Object.assign(
      {
        responseType: 'arraybuffer',
        headers: {
          Accept: fileMime,
        },
      },
      requestConfig
    )

    const response = await BaseApi.requester(config)
    const blob = new Blob([response.data], {
      type: fileMime,
    })
    FileSaver.saveAs(blob, fileName)
  }

  post(url, data, additionalHeaders) {
    return this.request('post', url, data, additionalHeaders)
  }

  put(url, data, additionalHeaders) {
    return this.request('put', url, data, additionalHeaders)
  }

  get(url, data, additionalHeaders) {
    return this.request('get', url, data, additionalHeaders)
  }

  delete(url, data, additionalHeaders) {
    return this.request('delete', url, data, additionalHeaders)
  }

  querystring(queryParams) {
    return Object.keys(queryParams)
      .filter((key) => queryParams[key] !== undefined)
      .map((key) => `${key}=${queryParams[key]}`)
      .join('&')
  }

  static get requester() {
    return requester
  }

  static get baseUrl() {
    return baseURL
  }
}

function handleError(errorResponse) {
  if (errorResponse) {
    Sentry.captureException(errorResponse)

    let response = errorResponse.response
    if (response) {
      // eslint-disable-next-line no-console
      console.error(
        `[Failed ${response.config.method.toUpperCase()}] ${
          response.config.url
        }:`,
        response.data
      )
      const errorObject =
        response.data.errors || response.data.error || response.data
      throw new ApiError(errorObject)
    }
  }
}

function logResult(response) {
  if (debug) {
    // eslint-disable-next-line no-console
    // console.info(
    //   `[${response.config.method}] ${response.config.url}:`,
    //   response.data
    // )
  }
}

function unwrapData(response) {
  return response.data
}
