import Vue from 'vue'
import Router from 'vue-router'
import { permissions } from '@/utils/permissions'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      component: () => import('./pages/Dashboard.vue'),
    },
    {
      path: '/dokumente/:id',
      name: 'extractedData',
      component: () => import('./pages/ExtractionView'),
      props: true,
    },

    {
      path: '/zahlungsdaten',
      name: 'PaymentData',
      component: () => import('./pages/PaymentData'),
    },

    {
      path: '/zahlungsdaten/:id/:year',
      name: 'PaymentDataView',
      props: true,
      component: () => import('./pages/PaymentDataView'),
    },

    {
      path: '/stammdaten',
      name: 'MasterData',
      component: () => import('./pages/MasterData'),
    },
    {
      path: '/stammdaten/neu',
      name: 'MasterDataCreate',
      props: {
        create: true,
      },
      component: () => import('./pages/MasterDataView'),
    },
    {
      path: '/stammdaten/:id',
      name: 'MasterDataView',
      props: true,
      component: () => import('./pages/MasterDataView'),
    },
    {
      path: '/reporting',
      name: 'Reporting',
      component: () => import('./pages/Reporting'),
      beforeEnter: (to, from, next) => {
        next(allow([permissions.closeBiSessionsGenerateReports]))
      },
    },
    {
      path: '/service',
      name: 'Service',
      component: () => import('./pages/Service'),
      beforeEnter: (to, from, next) => {
        next(
          allow([
            permissions.changePermissionsOfUser,
            permissions.changeMasterdata,
          ])
        )
      },
      children: [
        {
          path: 'importe',
          name: 'Service.Imports',
          component: () => import('./pages/service/Imports'),
        },
        {
          path: 'zahlungsjahre',
          name: 'Service.PaymentYearSettings',
          component: () => import('./pages/service/PaymentYearSettings'),
        },
        {
          path: 'stand-der-bearbeitung/:year?/:type?',
          name: 'Service.StatusStats',
          props: true,
          component: () => import('./pages/service/StatusStats'),
        },
        {
          path: 'version',
          name: 'Service.VersionInfo',
          props: true,
          component: () => import('./pages/service/VersionInfo'),
        },
        {
          path: 'hebesatzkonflikte',
          name: 'Service.Conflicts',
          props: true,
          component: () => import('./pages/service/Conflicts'),
        },
      ],
    },
    {
      path: '/admin',
      name: 'Admin',
      component: () => import('./pages/Admin'),
      redirect: { name: 'Admin.Roles' },
      beforeEnter: (to, from, next) => {
        next(allow([permissions.changePermissionsOfUser]))
      },
      children: [
        {
          path: 'benutzer',
          name: 'Admin.Roles',
          component: () => import('./pages/admin/UserRoles'),
        },
        {
          path: 'firmen',
          name: 'Admin.Companies',
          component: () => import('./pages/admin/Companies'),
        },
        {
          path: 'einstellungen',
          name: 'Admin.Settings',
          component: () => import('./pages/admin/Settings'),
        },
        {
          path: 'dokumente',
          name: 'Admin.AuditTrail',
          component: () => import('./pages/admin/AuditTrail'),
        },
        {
          path: 'logs',
          name: 'Admin.Logs',
          props: true,
          component: () => import('./pages/admin/Logs'),
        },
        {
          path: 'backup',
          name: 'Admin.Backup',
          props: true,
          component: () => import('./pages/admin/Backup'),
          beforeEnter: (to, from, next) => {
            next(allow([permissions.changePermissionsOfUser]))
          },
        },
        {
          path: 'zinssatz',
          name: 'Admin.InterestRates',
          props: true,
          component: () => import('./pages/admin/InterestRates'),
          beforeEnter: (to, from, next) => {
            next(allow([permissions.changeCustomizingData]))
          },
        },
        {
          path: 'zins-zeitraeume',
          name: 'Admin.DefaultInterestPeriods',
          props: true,
          component: () => import('./pages/admin/DefaultInterestPeriods'),
          beforeEnter: (to, from, next) => {
            next(allow([permissions.changeCustomizingData]))
          },
        },
      ],
    },
    {
      path: '/buchungen',
      name: 'BookingsOverview',
      component: () => import('./pages/bookings/BookingsOverview'),
      beforeEnter: (to, from, next) => {
        next(allow([permissions.closeBiSessionsGenerateReports]))
      },
    },
    {
      path: '/buchungen/:id',
      name: 'BookingsView',
      props: true,
      component: () => import('./pages/Bookings'),
      beforeEnter: (to, from, next) => {
        next(allow([permissions.closeBiSessionsGenerateReports]))
      },
    },
    {
      path: '/archiv',
      name: 'ArchiveOverview',
      component: () => import('./pages/Archive'),
    },
    {
      path: '/gewst-zerlegung',
      name: 'Zerlegung',
      component: () => import('./pages/Zerlegung'),
      beforeEnter: (to, from, next) => {
        next(allow([permissions.closeBiSessionsGenerateReports]))
      },
    },
    {
      path: '/gewst-zerlegung/neu',
      name: 'ZerlegungCreate',
      props: {
        create: true,
      },
      component: () => import('./pages/ZerlegungView'),
      beforeEnter: (to, from, next) => {
        next(allow([permissions.closeBiSessionsGenerateReports]))
      },
    },
    {
      path: '/gewst-zerlegung/:id',
      name: 'ZerlegungView',
      props: true,
      component: () => import('./pages/ZerlegungView'),
      beforeEnter: (to, from, next) => {
        next(allow([permissions.closeBiSessionsGenerateReports]))
      },
    },
    {
      path: '/no-access',
      name: 'NoPerm',
      props: true,
      component: () => import('./pages/Info'),
    },
    {
      path: '*',
      name: '404',
      component: () => import('./pages/NotFoundComponent.vue'),
    },
  ],
})

function allow(routePerms = []) {
  if (!routePerms.length) {
    return true
  }
  return routePerms.some((perm) => {
    return Vue.prototype.$ability.can(perm)
  })
}

export default router
