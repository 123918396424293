import BaseApi from './BaseApi'

class BookingsApi extends BaseApi {
  getAll(companyId, type, page, archived = false) {
    // Route::get('companies/{company}/bookings/', 'BookingController@getAll')

    const url = `companies/${companyId}/bookings`
    const query = [`type=${type}`, `page=${page}`]
    if (archived) {
      query.push(`archived=${archived}`)
    }
    return this.get(`${url}?${query.join('&')}`)
  }

  getEntry(id, page = 1) {
    // Route::get('bookings/{session}', 'BookingController@getBySession')
    return this.get(`/bookings/${id}?page=${page}`)
  }

  closeSession(id) {
    // Route::put('bookings/{session}/close', 'BookingController@closeSession')
    return this.put(`/bookings/${id}/close`)
  }

  approveSession(id, approved_at) {
    // Route::put('bookings/{session}/approve', 'BookingController@approveSession')
    return this.put(`bookings/${id}/approve`, { approved_at })
  }

  getQueues(id) {
    // Route::get('bookings/{session}/queues', 'BookingController@getQueues')
    return this.get(`bookings/${id}/queues`)
  }

  rejectSession(id, comment) {
    // Route::put('bookings/{session}/reject', 'BookingController@rejectSession')
    return this.put(`bookings/${id}/reject`, { comment })
  }

  submitSession(id, comment) {
    // Route::put('bookings/{session}/submit', 'BookingController@submitSession')
    return this.put(`bookings/${id}/submit`, { comment })
  }

  correctBooking(sessionId, bookings) {
    return this.put(`bookings/${sessionId}/correct`, { changes: bookings })
  }

  cancelBookings(sessionId, bookings) {
    return this.put(`bookings/${sessionId}/cancel`, { storno: bookings })
  }

  retriggerIdocExport(sessionId) {
    return this.put(`bookings/${sessionId}/retrigger-failed-jobs`)
  }

  revertSessionToClosed(sessionId) {
    return this.put(`bookings/${sessionId}/revert-to-closed`)
  }

  archiveSession(sessionId) {
    return this.put(`bookings/${sessionId}/archive`)
  }
  generateReports(id) {
    // Route::put('bookings/{session}/generate_report', 'BookingController@generateReports')
    return this.put(`bookings/${id}/generate_report`)
  }

  createPrepaymentSession(companyId, year, quartal) {
    // Route::put('bookings/prepayments/create', 'BookingController@createPrepaymentSession')
    return this.put(
      `bookings/prepayments/create?company_id=${companyId}&year=${year}&quartal=${quartal}`
    )
  }

  getPrePaymentsBiLog(company_id, year, quartal) {
    return this.get(
      `/prePaymentsBiLog?company_id=${company_id}&quartal=${quartal}&year=${year}`
    )
  }

  removePrePaymentsBiLog(id) {
    return this.delete(`/prePaymentsBiLog/${id}`)
  }

  getSessionLogs(id) {
    return this.get(`/sessionLogs/${id}`)
  }
}

export default new BookingsApi()
