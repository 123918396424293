import BaseApi from './BaseApi'

class CompaniesApi extends BaseApi {
  getAll() {
    return this.get('/companies')
  }
  getCompany(companyId) {
    return this.get(`/companies/${companyId}`)
  }
  store(company) {
    return this.post('/companies', company)
  }
  remove(companyId) {
    return this.delete(`/companies/${companyId}`)
  }
  update(companyId, company) {
    return this.put(`/companies/${companyId}`, company)
  }
  conflicting(companyId, page = 1) {
    return this.get(
      `companies/${companyId}/masterData/conflictingMunicipalityRates?page=${page}`
    )
  }
}

export default new CompaniesApi()
