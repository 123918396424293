const Keycloak = window.Keycloak
const envVars = window.__ENV_VARS__
export const keycloak = new Keycloak({
  realm: envVars.VUE_APP_KEYCLOAK_REALM,
  url: envVars.VUE_APP_KEYCLOAK_AUTH_SERVER_URL,
  clientId: envVars.VUE_APP_KEYCLOAK_RESOURCE,
})

export function getAuthHeader() {
  return {
    Authorization: `Bearer ${keycloak.token}`,
  }
}
