import { defineAbility } from '@casl/ability'

const permissions = {
  readData: 'read data',
  addPaymentData: 'add payment data',

  closeBiSessionsGenerateReports: 'close bi sessions, generate reports',
  changeMasterdata: 'change masterdata',

  changeCustomizingData: 'change customizing data',
  changePermissionsOfUser: 'change permissions of a user',

  createDeleteCompanies: 'create/delete companies',
  changeCompanies: 'change companies',
}

const ability = defineAbility((can, cannot) => {
  // by default the user has no abilities
})

export { permissions, ability }
