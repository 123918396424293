import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import vuetify from 'vuetify'
import { abilitiesPlugin } from '@casl/vue'
import { ability, permissions } from '@/utils/permissions'

// css and fonts
import 'vuetify/dist/vuetify.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'roboto-fontface/css/roboto/roboto-fontface.css'

// lang files
import de from 'vuetify/es5/locale/de'

import * as Sentry from '@sentry/vue'
import { keycloak } from '@/api/keycloakAuth'

if (
  process.env.NODE_ENV === 'production' &&
  window.__ENV_VARS__.VUE_APP_SENTRY_DSN
) {
  Sentry.init({
    Vue: Vue,
    dsn: window.__ENV_VARS__.VUE_APP_SENTRY_DSN,
  })
}

Vue.use(vuetify, {
  theme: {
    primary: '#2D3A43',
    secondary: '#b0bec5',
    accent: '#1264af',
    error: '#b71c1c',
    backgroundColor: '#e3f0f8',
  },
  lang: {
    locales: { de },
    current: 'de',
  },
})

Vue.use(abilitiesPlugin, ability)
Vue.config.productionTip = false

Vue.prototype.$permissions = permissions

keycloak
  .init({
    promiseType: 'native',
  })
  .then(async (authenticated) => {
    if (authenticated) {
      setupPermissionManagement()
      await setupStoreData()
      const { username } = store.getters.currentUser
      Sentry.setUser({ username })
      setInterval(() => {
        keycloak.updateToken(30)
        store.dispatch('updateUser')
      }, 10 * 1000)
      startApp()
    } else {
      keycloak.login({ redirectUri: location.href })
    }
  })

function setupPermissionManagement() {
  router.onReady(() => {
    async function checkAccess({ ability }) {
      const isNoPermRoute = router.app?.$route.name === 'NoPerm'
      const canRead = ability.can(permissions.readData, 'all')
      if (!canRead && !isNoPermRoute) {
        router.replace({ name: 'NoPerm' })
      }
    }
    Vue.prototype.$ability.on('updated', checkAccess)
  })
}

async function setupStoreData() {
  await store.dispatch('updateUser')

  if (Vue.prototype.$ability.can(permissions.readData)) {
    await store.dispatch('fetchCompanies')
    await store.dispatch('getDefaultInterestPeriods')
  }
}

function startApp() {
  new Vue({
    data() {
      return {
        snackbar: {
          show: false,
          text: '',
          type: 'success',
        },
      }
    },
    methods: {
      setSnackbar(type, text) {
        let message = text
        if (Array.isArray(text)) {
          message = text.join('; ')
        }

        this.snackbar.text = message
        this.snackbar.type = type
        this.snackbar.show = true
      },
    },
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app')
}
