import BaseApi from './BaseApi'

class AuthApi extends BaseApi {
  user() {
    return this.get('users')
  }

  getAll() {
    return this.get('users/getAll')
  }

  logout() {
    return this.get('logout')
  }
}

export default new AuthApi()
