export class ApiError extends Error {
  constructor(errorObject, ...params) {
    super(...params)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError)
    }

    this.errorObject = errorObject
    this.date = new Date()
  }
}
