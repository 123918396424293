import Vue from 'vue'
import Vuex from 'vuex'
import {
  SET_DASHBOARD_FILTER,
  SET_CURRENT_COMPANY,
  SET_COMPANIES,
  SET_USER,
  SET_PERMISSIONS,
  SET_DEFAULT_INTEREST_PERIODS,
  SET_SESSION_LOGS,
} from '@/store/mutations'

import companiesApi from '@/api/companiesApi'
import interestRatesApi from '@/api/interestRatesApi'
import authApi from '@/api/authApi'
import { AbilityBuilder } from '@casl/ability'
import bookingsApi from '@/api/bookingsApi'

const currentCompanyStorageKey = `gewerbesteuer.currentCompany`

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    currentCompanyId: localStorage.getItem(currentCompanyStorageKey),
    dashboardFilter: null,
    user: null,
    permissions: [],
    companies: [],
    defaultInterestPeriods: [],
    sessionLogs: [],
  },
  getters: {
    currentUser: (state) => state.user,
    permissions: (state) => state.permissions,

    currentCompany: (state, getters) => {
      const id = state.currentCompanyId
      return getters.companies.find((el) => String(el.id) === String(id))
    },
    currentCompanyRGB(state, getters) {
      if (getters.currentCompany) {
        const hex = getters.currentCompany.main_color
        let c
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
          c = hex.substring(1).split('')
          if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
          }
          c = '0x' + c.join('')
          return [(c >> 16) & 255, (c >> 8) & 255, c & 255]
        }
      }
      return [250, 250, 250]
    },
    dashboardFilter: (state) => state.dashboardFilter,
    companies: (state) => state.companies,
  },
  actions: {
    setCurrentCompany({ commit }, companyId) {
      commit(SET_CURRENT_COMPANY, companyId)
    },
    setDashboardFilter({ commit, getters }, filter) {
      if (filter === getters.dashboardFilter) {
        commit(SET_DASHBOARD_FILTER, null)
      } else {
        commit(SET_DASHBOARD_FILTER, filter)
      }
    },

    async fetchCompanies({ commit, getters }) {
      const companies = await companiesApi.getAll()
      commit(SET_COMPANIES, companies)
      if (!getters.currentCompany && companies.length) {
        // when there is no initial company Id defined, we simply select the first entry
        const [firstCompany] = companies
        commit(SET_CURRENT_COMPANY, firstCompany.id)
      }
      return companies
    },
    async updateUser({ commit, state }) {
      const user = await authApi.user()
      commit(SET_USER, user)
      commit(SET_PERMISSIONS, user)

      return user
    },
    async getDefaultInterestPeriods({ commit }) {
      const periods = await interestRatesApi.getDefaultInterestPeriods()
      commit(SET_DEFAULT_INTEREST_PERIODS, periods)
    },

    async updateBookingSessionLogs({ commit }, sessionId) {
      const sessionLogs = await bookingsApi.getSessionLogs(sessionId)
      commit(SET_SESSION_LOGS, sessionLogs)
    },
  },
  mutations: {
    [SET_CURRENT_COMPANY](state, id) {
      state.currentCompanyId = id
    },
    [SET_DASHBOARD_FILTER](state, value) {
      state.dashboardFilter = value
    },
    [SET_COMPANIES](state, companies) {
      state.companies = companies
    },
    [SET_USER](state, user) {
      state.user = user
    },
    [SET_PERMISSIONS](state, user) {
      const permissionsInRoles = user.roles.reduce((permissions, role) => {
        return permissions.concat(role.permissions.map((p) => p.name))
      }, [])
      Vue.set(state, 'permissions', Array.from(new Set(permissionsInRoles)))
    },
    [SET_DEFAULT_INTEREST_PERIODS](state, periods) {
      state.defaultInterestPeriods = periods
    },
    [SET_SESSION_LOGS](state, session) {
      state.sessionLogs = session
    },
  },
  strict: process.env.NODE_ENV !== 'production',
})

store.watch(
  (state) => state.permissions,
  (permissions) => {
    const { can, rules } = new AbilityBuilder()
    permissions.forEach((permission) => {
      can(permission)
    })
    Vue.prototype.$ability.update(rules)
  }
)

store.watch(
  (state) => state.currentCompanyId,
  (companyId) => {
    localStorage.setItem(currentCompanyStorageKey, companyId)
  }
)

export default store
