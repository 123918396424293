export const SET_MASTER_DATA = 'SET_MASTER_DATA'
export const SET_PAYMENT_DATA = 'SET_PAYMENT_DATA'
export const ADD_UPLOADED_DOCUMENT = 'ADD_UPLOADED_DOCUMENT'
export const SET_CURRENT_DOCUMENT = 'SET_CURRENT_DOCUMENT'
export const SET_MASTER_DATA_FOR_DOCUMENT = 'SET_MASTER_DATA_FOR_DOCUMENT'
export const RESET_DOCUMENT_DATA = 'RESET_DOCUMENT_DATA'
export const SET_PAYMENT_ENTRIES_FOR_DOCUMENT =
  'SET_PAYMENT_ENTRIES_FOR_DOCUMENT'
export const SET_DASHBOARD_FILTER = 'SET_DASHBOARD_FILTER'
export const SET_COMPANIES = 'SET_COMPANIES'
export const SET_PERMISSIONS = 'SET_PERMISSIONS'
export const SET_USER = 'SET_USER'
export const SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY'
export const SET_DEFAULT_INTEREST_PERIODS = 'SET_DEFAULT_INTEREST_PERIODS'

export const SET_SESSION_LOGS = 'SET_SESSION_LOGS'
